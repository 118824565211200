import * as React from 'react'
import { useForm, UseFormGetValues, UseFormSetValue } from 'react-hook-form'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { Swiper } from 'swiper/types'

import {
  SearchCondition as SearchConditionPresenter,
  IProps as ISearchConditionPresenterProps,
} from './presenter'
export { makeSearchConditionSummaryText } from './presenter'
import {
  trackingUserContext,
  searchEventIdContext,
} from '@/contexts/trackingContexts'
import { isMobileDisplay } from '@/utils/devise'
import { trackEvent } from '@/utils/analyze'

interface IProps
  extends Omit<
    ISearchConditionPresenterProps,
    | 'setSwiper'
    | 'register'
    | 'setValue'
    | 'currentPrefectureId'
    | 'setPrefectureId'
    | 'areas'
    | 'stations'
    | 'selectedPrefectures'
    | 'selectedGeocodes'
    | 'selectedStations'
    | 'selectedSearchType'
    | 'selectedReserveType'
    | 'changeSearchTypeHandler'
    | 'openPrefectureHandler'
    | 'closePrefectureHandler'
    | 'resetPrefectureHandler'
    | 'openAreaSelectHandler'
    | 'closeAreaSelectHandler'
    | 'transitionEndHandler'
    | 'selectPrefectureHandler'
    | 'selectAllGeocodeHandler'
    | 'deselectAllGeocodeHandler'
    | 'selectGeocodeHandler'
    | 'selectStationHandler'
    | 'prevHandler'
    | 'submitHandler'
    | 'resetHandler'
  > {
  showSearchCount?: boolean
  getValues: UseFormGetValues<ISearchConditionState>
  submitHandler: (state: ISearchConditionState) => void
}

const TRANSITION_SPEED = 0
const MOBIL_TRANSITION_SPEED = 300
const DEFAULT_STATE_RESERVE_TYPE = 'normal' as const
const DEFAULT_STATE_SEARCH = {
  search_type: 'sitter' as const,
  geocode: {},
  station: {},
  available_schedule: 1,
  nursery_age0_0: 0,
  nursery_age0_1to2: 0,
  nursery_age0_3to5: 0,
  nursery_age0_6to11: 0,
  nursery_age1: 0,
  nursery_age2: 0,
  nursery_age3to6: 0,
  nursery_age7to12: 0,
  acceptable_number1: 0,
  acceptable_number2: 0,
  cao_bs: 0,
  tokyo_bs_tmp_use: 0,
  tokyo_bs_waiting: 0,
  regular_discount: 0,
  campaign: 0,
  housekeeping_subsidy: 0,
  hourly_wage_from_1000_to_1500: 0,
  hourly_wage_from_1500_to_2000: 0,
  hourly_wage_from_2000_to_2500: 0,
  hourly_wage_from_2500_to_3000: 0,
  hourly_wage_from_3000: 0,
  housekeeping_clean_up_support: 0,
  housekeeping_tidy_up_support: 0,
  housekeeping_home_cooking_support: 0,
  housekeeping_shopping: 0,
  housekeeping_cooking_in_advance_support: 0,
  housekeeping_home_party_cooking: 0,
  housekeeping_clean_with_cooking: 0,
  housekeeping_cooking_for_food_industry: 0,
  tutor_kindergarten: 0,
  tutor_elementary: 0,
  tutor_jrhighschool: 0,
  tutor_highschool: 0,
  tutor_tutor_supplementary: 0,
  tutor_english_bilingual: 0,
  tutor_entrance_exam: 0,
  tutor_tokyo_university: 0,
  tutor_keio_university: 0,
  tutor_waseda_university: 0,
  tutor_sophia_university: 0,
  tutor_other_university: 0,
  tutor_elementary_exam: 0,
  tutor_jrhighschool_exam: 0,
  tutor_highschool_exam: 0,
  tutor_college_exam: 0,
  tutor_elementary_exam_ex: 0,
  tutor_jrhighschool_exam_ex: 0,
  tutor_highschool_exam_ex: 0,
  tutor_college_exam_ex: 0,
  tutor_national_language: 0,
  tutor_arithmetic: 0,
  tutor_science: 0,
  tutor_social_studies: 0,
  tutor_english: 0,
  tutor_english_speaking: 0,
  tutor_toeic: 0,
  tutor_toefl: 0,
  tutor_english_exam: 0,
  tutor_math: 0,
  tutor_ancient_writing: 0,
  tutor_chinese_classics: 0,
  tutor_essay: 0,
  tutor_physics: 0,
  tutor_chemistry: 0,
  tutor_biology: 0,
  tutor_geoscience: 0,
  tutor_japanese_history: 0,
  tutor_world_history: 0,
  tutor_geography: 0,
  tutor_ethics: 0,
  tutor_politics_economics: 0,
  cred: {
    childminder: 0,
    kindergarten_teacher: 0,
    nurse: 0,
  },
  doula_certification: 0,
  pickup_only_support: 0,
  mid_night_support: 0,
  handicap_support: 0,
  tutor_enable_flag: 0,
  english_lesson_support: 0,
  music_lesson_support: 0,
  sports_lesson_support: 0,
  art_lesson_support: 0,
  other_appeal_support: 0,
  morning_support: 0,
  night_support: 0,
  sick_bsl_fever: 0,
  sick_bsl_unknown_disease: 0,
  sick_bsl_infection: 0,
  sick_bsl_unknown: 0,
  illness_bsl_fever: 0,
  illness_bsl_unknown_disease: 0,
  illness_bsl_infection: 0,
  new_debuted_sitter: 0,
  foreign_language_support: 0,
  semi_childcare_certification: 0,
  no_option: 0,
  word: '',
}
const DEFAULT_STATE_REGULAR_SEARCH = {
  monday: undefined,
  tuesday: undefined,
  wednesday: undefined,
  thursday: undefined,
  friday: undefined,
  saturday: undefined,
  sunday: undefined,
}

export const initConditionStateWithSearchType = (
  selectedSerchType: ISearchType,
  getValues: UseFormGetValues<ISearchConditionState>,
  setValue: UseFormSetValue<ISearchConditionState>,
) => {
  const {
    search_type,
    available_schedule,
    geocode,
    station,
    nursery_age0_0,
    nursery_age0_1to2,
    nursery_age0_3to5,
    nursery_age0_6to11,
    nursery_age1,
    nursery_age2,
    nursery_age3to6,
    nursery_age7to12,
    acceptable_number1,
    acceptable_number2,
    ...search
  } = getValues('search')
  const regularSearch = getValues('regular_search')

  // 種別に変更がないときは何もしない
  if (search_type === selectedSerchType) {
    return
  }
  // ベビーシッター、産前産後の場合は保育可能年齢・人数を維持する
  if (selectedSerchType === 'sitter' || selectedSerchType === 'doula') {
    setValue('search', {
      ...search,
      ...DEFAULT_STATE_SEARCH,
      available_schedule,
      search_type: selectedSerchType,
      geocode,
      station,
      nursery_age0_0,
      nursery_age0_1to2,
      nursery_age0_3to5,
      nursery_age0_6to11,
      nursery_age1,
      nursery_age2,
      nursery_age3to6,
      nursery_age7to12,
      acceptable_number1,
      acceptable_number2,
    })
  } else {
    setValue('search', {
      ...search,
      ...DEFAULT_STATE_SEARCH,
      available_schedule,
      search_type: selectedSerchType,
      geocode,
      station,
    })
  }
  setValue('regular_search', {
    ...regularSearch,
    ...DEFAULT_STATE_REGULAR_SEARCH,
    sunday: regularSearch?.sunday,
    monday: regularSearch?.monday,
    tuesday: regularSearch?.tuesday,
    wednesday: regularSearch?.wednesday,
    thursday: regularSearch?.thursday,
    friday: regularSearch?.friday,
    saturday: regularSearch?.saturday,
  })
}

export const makeSearchConditionTrackingData = (
  state: ISearchConditionState,
  t: TFunction,
) => {
  const items: string[] = []

  // 利用目的
  if (state?.search?.search_type) {
    const search_type = t(
      `search:params.search.searchType.${state.search.search_type}`,
    ) as string
    items.push(`利用目的: ${search_type}`)
  }

  // 予約可能なサポーター
  if (state?.search?.available_schedule) {
    items.push(t('search:params.search.availableSchedule'))
  }

  // 市区町村
  if (state?.search?.geocode) {
    // 一次元配列に変換
    const geocodes: IGeocode[] = ([] as IGeocode[]).concat(
      ...Object.values(state.search.geocode),
    )
    if (geocodes.length > 0) {
      const geocodeLabel = geocodes
        .reduce((prev, geocode) => {
          return [...prev, geocode.name]
        }, [] as string[])
        .join(', ')

      items.push(`市区町村: ${geocodeLabel}`)
    }
  }

  // 主要駅
  if (state?.search?.station) {
    // 一次元配列に変換
    const stations: IStation[] = ([] as IStation[]).concat(
      ...Object.values(state.search.station),
    )

    if (stations.length > 0) {
      const stationLabel = stations
        .reduce((prev, station) => {
          return [...prev, station.name]
        }, [] as string[])
        .join(', ')

      items.push(`主要駅: ${stationLabel}`)
    }
  }

  // 予約方法
  if (state.reserveType) {
    const reserveType = t(
      `search:params.search.reserveType.${state.reserveType}`,
    ) as string

    items.push(`予約方法: ${reserveType}`)
  }

  // 曜日指定
  const weeks: string[] = []
  if (state?.regular_search?.sunday) {
    weeks.push(t('search:params.regularSearch.sunday'))
  }
  if (state?.regular_search?.monday) {
    weeks.push(t('search:params.regularSearch.monday'))
  }
  if (state?.regular_search?.tuesday) {
    weeks.push(t('search:params.regularSearch.tuesday'))
  }
  if (state?.regular_search?.wednesday) {
    weeks.push(t('search:params.regularSearch.wednesday'))
  }
  if (state?.regular_search?.thursday) {
    weeks.push(t('search:params.regularSearch.thursday'))
  }
  if (state?.regular_search?.friday) {
    weeks.push(t('search:params.regularSearch.friday'))
  }
  if (state?.regular_search?.saturday) {
    weeks.push(t('search:params.regularSearch.saturday'))
  }
  if (weeks.length > 0) {
    items.push(`曜日指定: ${weeks.join(', ')}`)
  }

  // 指導対象
  const guidances: string[] = []
  if (state?.search?.tutor_kindergarten) {
    guidances.push(t('search:params.search.tutorKindergarten'))
  }
  if (state?.search?.tutor_elementary) {
    guidances.push(t('search:params.search.tutorElementary'))
  }
  if (state?.search?.tutor_jrhighschool) {
    guidances.push(t('search:params.search.tutorJrhighschool'))
  }
  if (state?.search?.tutor_highschool) {
    guidances.push(t('search:params.search.tutorHighschool'))
  }
  if (guidances.length > 0) {
    items.push(`指導対象: ${guidances.join(', ')}`)
  }

  // 保育可能年齢
  const nurseryAges: string[] = []
  if (state?.search?.nursery_age0_0) {
    nurseryAges.push(t('search:params.search.nurseryAge0-0'))
  }
  if (state?.search?.nursery_age0_1to2) {
    nurseryAges.push(t('search:params.search.nurseryAge0-1to2'))
  }
  if (state?.search?.nursery_age0_3to5) {
    nurseryAges.push(t('search:params.search.nurseryAge0-3to5'))
  }
  if (state?.search?.nursery_age0_6to11) {
    nurseryAges.push(t('search:params.search.nurseryAge0-6to11'))
  }
  if (state?.search?.nursery_age1) {
    nurseryAges.push(t('search:params.search.nurseryAge1'))
  }
  if (state?.search?.nursery_age2) {
    nurseryAges.push(t('search:params.search.nurseryAge2'))
  }
  if (state?.search?.nursery_age3to6) {
    nurseryAges.push(t('search:params.search.nurseryAge3to6'))
  }
  if (state?.search?.nursery_age7to12) {
    nurseryAges.push(t('search:params.search.nurseryAge7to12'))
  }
  if (nurseryAges.length > 0) {
    items.push(`保育可能年齢: ${nurseryAges.join(', ')}`)
  }

  // 保育人数
  const acceptableNumbers: string[] = []
  if (state?.search?.acceptable_number1) {
    acceptableNumbers.push(t('search:params.search.acceptableNumber1'))
  }
  if (state?.search?.acceptable_number2) {
    acceptableNumbers.push(t('search:params.search.acceptableNumber2'))
  }
  if (acceptableNumbers.length > 0) {
    items.push(`保育人数: ${acceptableNumbers.join(', ')}`)
  }

  // 割引対象
  const discounts: string[] = []
  if (state?.search?.cao_bs) {
    discounts.push(t('search:params.search.caoBs'))
  }
  if (state?.search?.tokyo_bs_tmp_use) {
    discounts.push(t('search:params.search.tokyoBsTmpUse'))
  }
  if (state?.search?.tokyo_bs_waiting) {
    discounts.push(t('search:params.search.tokyoBsWaiting'))
  }
  if (state?.search?.regular_discount) {
    discounts.push(t('search:params.search.regularDiscount'))
  }
  if (state?.search?.campaign) {
    discounts.push(t('search:params.search.campaign'))
  }
  if (state?.search?.housekeeping_subsidy) {
    discounts.push(t('search:params.search.housekeeping_subsidy'))
  }
  if (discounts.length > 0) {
    items.push(`割引対象: ${discounts.join(', ')}`)
  }

  // 時給
  const hourlyWages: string[] = []
  if (state?.search?.hourly_wage_from_1000_to_1500) {
    hourlyWages.push(t('search:params.search.hourlyWageFrom1000To1500'))
  }
  if (state?.search?.hourly_wage_from_1500_to_2000) {
    hourlyWages.push(t('search:params.search.hourlyWageFrom1500To2000'))
  }
  if (state?.search?.hourly_wage_from_2000_to_2500) {
    hourlyWages.push(t('search:params.search.hourlyWageFrom2000To2500'))
  }
  if (state?.search?.hourly_wage_from_2500_to_3000) {
    hourlyWages.push(t('search:params.search.hourlyWageFrom2500To3000'))
  }
  if (state?.search?.hourly_wage_from_3000) {
    hourlyWages.push(t('search:params.search.hourlyWageFrom3000'))
  }
  if (hourlyWages.length > 0) {
    items.push(`時給: ${hourlyWages.join(', ')}`)
  }

  // 資格
  const creds: string[] = []
  if (state?.search?.cred?.childminder) {
    creds.push(t('search:params.search.cred.childminder'))
  }
  if (state?.search?.cred?.kindergarten_teacher) {
    creds.push(t('search:params.search.cred.kindergartenTeacher'))
  }
  if (state?.search?.cred?.nurse) {
    creds.push(t('search:params.search.cred.nurse'))
  }
  if (state?.search?.doula_certification) {
    creds.push(t('search:params.search.doulaCertification'))
  }
  if (creds.length > 0) {
    items.push(`資格: ${creds.join(', ')}`)
  }

  // 家庭教師
  const tutors: string[] = []
  if (state?.search?.tutor_tutor_supplementary) {
    tutors.push(t('search:params.search.tutorTutorSupplementary'))
  }
  if (state?.search?.tutor_english_bilingual) {
    tutors.push(t('search:params.search.tutorEnglishBilingual'))
  }
  if (state?.search?.tutor_entrance_exam) {
    tutors.push(t('search:params.search.tutorEntranceExam'))
  }
  if (state?.search?.tutor_tokyo_university) {
    tutors.push(t('search:params.search.tutorTokyoUniversity'))
  }
  if (state?.search?.tutor_keio_university) {
    tutors.push(t('search:params.search.tutorKeioUniversity'))
  }
  if (state?.search?.tutor_waseda_university) {
    tutors.push(t('search:params.search.tutorWasedaUniversity'))
  }
  if (state?.search?.tutor_sophia_university) {
    tutors.push(t('search:params.search.tutorSophiaUniversity'))
  }
  if (state?.search?.tutor_other_university) {
    tutors.push(t('search:params.search.tutorOtherUniversity'))
  }
  if (tutors.length > 0) {
    items.push(`家庭教師: ${tutors.join(', ')}`)
  }

  // 受験
  const exams: string[] = []
  if (state?.search?.tutor_elementary_exam) {
    exams.push(t('search:params.search.tutorElementaryExam'))
  }
  if (state?.search?.tutor_jrhighschool_exam) {
    exams.push(t('search:params.search.tutorJrhighschoolExam'))
  }
  if (state?.search?.tutor_highschool_exam) {
    exams.push(t('search:params.search.tutorHighschoolExam'))
  }
  if (state?.search?.tutor_college_exam) {
    exams.push(t('search:params.search.tutorCollegeExam'))
  }
  if (state?.search?.tutor_elementary_exam_ex) {
    exams.push(t('search:params.search.tutorElementaryExamEx'))
  }
  if (state?.search?.tutor_jrhighschool_exam_ex) {
    exams.push(t('search:params.search.tutorJrhighschoolExamEx'))
  }
  if (state?.search?.tutor_highschool_exam_ex) {
    exams.push(t('search:params.search.tutorHighschoolExamEx'))
  }
  if (state?.search?.tutor_college_exam_ex) {
    exams.push(t('search:params.search.tutorCollegeExamEx'))
  }
  if (exams.length > 0) {
    items.push(`受験: ${exams.join(', ')}`)
  }

  // 科目
  const subjects: string[] = []
  if (state?.search?.tutor_national_language) {
    subjects.push(t('search:params.search.tutorNationalLanguage'))
  }
  if (state?.search?.tutor_arithmetic) {
    subjects.push(t('search:params.search.tutorArithmetic'))
  }
  if (state?.search?.tutor_science) {
    subjects.push(t('search:params.search.tutorScience'))
  }
  if (state?.search?.tutor_social_studies) {
    subjects.push(t('search:params.search.tutorSocialStudies'))
  }
  if (state?.search?.tutor_english) {
    subjects.push(t('search:params.search.tutorEnglish'))
  }
  if (state?.search?.tutor_english_speaking) {
    subjects.push(t('search:params.search.tutorEnglishSpeaking'))
  }
  if (state?.search?.tutor_toeic) {
    subjects.push(t('search:params.search.tutorToeic'))
  }
  if (state?.search?.tutor_toefl) {
    subjects.push(t('search:params.search.tutorToefl'))
  }
  if (state?.search?.tutor_english_exam) {
    subjects.push(t('search:params.search.tutorEnglishExam'))
  }
  if (state?.search?.tutor_math) {
    subjects.push(t('search:params.search.tutorMath'))
  }
  if (state?.search?.tutor_ancient_writing) {
    subjects.push(t('search:params.search.tutorAncientWriting'))
  }
  if (state?.search?.tutor_chinese_classics) {
    subjects.push(t('search:params.search.tutorChineseClassics'))
  }
  if (state?.search?.tutor_essay) {
    subjects.push(t('search:params.search.tutorEssay'))
  }
  if (state?.search?.tutor_physics) {
    subjects.push(t('search:params.search.tutorPhysics'))
  }
  if (state?.search?.tutor_chemistry) {
    subjects.push(t('search:params.search.tutorChemistry'))
  }
  if (state?.search?.tutor_biology) {
    subjects.push(t('search:params.search.tutorBiology'))
  }
  if (state?.search?.tutor_geoscience) {
    subjects.push(t('search:params.search.tutorGeoscience'))
  }
  if (state?.search?.tutor_japanese_history) {
    subjects.push(t('search:params.search.tutorJapaneseHistory'))
  }
  if (state?.search?.tutor_world_history) {
    subjects.push(t('search:params.search.tutorWorldHistory'))
  }
  if (state?.search?.tutor_geography) {
    subjects.push(t('search:params.search.tutorGeography'))
  }
  if (state?.search?.tutor_ethics) {
    subjects.push(t('search:params.search.tutorEthics'))
  }
  if (state?.search?.tutor_politics_economics) {
    subjects.push(t('search:params.search.tutorPoliticsEconomics'))
  }
  if (subjects.length > 0) {
    items.push(`科目: ${subjects.join(', ')}`)
  }

  const supports: string[] = []
  // サポート内容
  if (state?.search?.pickup_only_support) {
    supports.push(t('search:params.search.pickupOnlySupport'))
  }
  if (state?.search?.mid_night_support) {
    supports.push(t('search:params.search.midNightSupport'))
  }
  if (state?.search?.handicap_support) {
    supports.push(t('search:params.search.handicapSupport'))
  }
  if (state?.search?.tutor_enable_flag) {
    supports.push(t('search:params.search.tutorEnableFlag'))
  }
  if (state?.search?.english_lesson_support) {
    supports.push(t('search:params.search.englishLessonSupport'))
  }
  if (state?.search?.music_lesson_support) {
    supports.push(t('search:params.search.musicLessonSupport'))
  }
  if (state?.search?.sports_lesson_support) {
    supports.push(t('search:params.search.sportsLessonSupport'))
  }
  if (state?.search?.art_lesson_support) {
    supports.push(t('search:params.search.artLessonSupport'))
  }
  if (state?.search?.other_appeal_support) {
    supports.push(t('search:params.search.otherAppealSupport'))
  }
  if (state?.search?.housekeeping_clean_up_support) {
    supports.push(t('search:params.search.housekeepingCleanUpSupport'))
  }
  if (state?.search?.housekeeping_tidy_up_support) {
    supports.push(t('search:params.search.housekeepingTidyUpSupport'))
  }
  if (state?.search?.housekeeping_home_cooking_support) {
    supports.push(t('search:params.search.housekeepingHomeCookingSupport'))
  }
  if (state?.search?.housekeeping_shopping) {
    supports.push(t('search:params.search.housekeepingShopping'))
  }
  if (state?.search?.housekeeping_cooking_in_advance_support) {
    supports.push(t('search:params.search.housekeepingCookingInAdvanceSupport'))
  }
  if (state?.search?.housekeeping_home_party_cooking) {
    supports.push(t('search:params.search.housekeepingHomePartyCooking'))
  }
  if (supports.length > 0) {
    items.push(`サポート内容: ${supports.join(', ')}`)
  }

  // 対応時間帯
  const availableHours: string[] = []
  if (state?.search?.morning_support) {
    availableHours.push(t('search:params.search.morningSupport'))
  }
  if (state?.search?.night_support) {
    availableHours.push(t('search:params.search.nightSupport'))
  }
  if (availableHours.length > 0) {
    items.push(`対応時間帯: ${availableHours.join(', ')}`)
  }

  // 病児
  const sicks: string[] = []
  if (state?.search?.sick_bsl_fever) {
    sicks.push(t('search:params.search.sickBslFever'))
  }
  if (state?.search?.sick_bsl_unknown_disease) {
    sicks.push(t('search:params.search.sickBslUnknownDisease'))
  }
  if (state?.search?.sick_bsl_infection) {
    sicks.push(t('search:params.search.sickBslInfection'))
  }
  if (state?.search?.sick_bsl_unknown) {
    sicks.push(t('search:params.search.sickBslUnknown'))
  }
  if (sicks.length > 0) {
    items.push(`病児: ${sicks.join(', ')}`)
  }

  // 病後児
  const illnesses: string[] = []
  if (state?.search?.illness_bsl_fever) {
    illnesses.push(t('search:params.search.illnessBslFever'))
  }
  if (state?.search?.illness_bsl_unknown_disease) {
    illnesses.push(t('search:params.search.illnessBslUnknownDisease'))
  }
  if (state?.search?.illness_bsl_infection) {
    illnesses.push(t('search:params.search.illnessBslInfection'))
  }
  if (illnesses.length > 0) {
    items.push(`病後児: ${illnesses.join(', ')}`)
  }

  // 特徴
  const characteristics: string[] = []
  if (state?.search?.no_option) {
    characteristics.push(t('search:params.search.noOption'))
  }
  if (state?.search?.foreign_language_support) {
    characteristics.push(t('search:params.search.foreignLanguageSupport'))
  }
  if (state?.search?.pickup_only_support) {
    characteristics.push(t('search:params.search.pickupOnlySupport'))
  }
  if (state?.search?.handicap_support) {
    characteristics.push(t('search:params.search.handicapSupport'))
  }
  if (state?.search?.new_debuted_sitter) {
    characteristics.push(t('search:params.search.newDebutedSitter'))
  }
  if (state?.search?.housekeeping_clean_with_cooking) {
    characteristics.push(t('search:params.search.housekeepingCleanWithCooking'))
  }
  if (state?.search?.housekeeping_cooking_for_food_industry) {
    characteristics.push(
      t('search:params.search.housekeepingCookingForFoodIndustry'),
    )
  }
  if (state?.search?.semi_childcare_certification) {
    characteristics.push(t('search:params.search.semiChildcareCertification'))
  }
  if (characteristics.length > 0) {
    items.push(`特徴: ${characteristics.join(', ')}`)
  }

  if (state?.search?.word) {
    items.push(`キーワードで絞る: ${state.search.word}`)
  }

  return items
}

export const SearchCondition = ({
  submitLabel,
  prefectures,
  showSearchCount,
  getValues,
  ...props
}: IProps): JSX.Element => {
  const { t } = useTranslation()
  const user = React.useContext(trackingUserContext)
  const eventIds = React.useContext(searchEventIdContext)
  const reserveType = getValues('reserveType')
  const search = getValues('search')
  const regularSearch = getValues('regular_search')
  const useFormObject = useForm<ISearchConditionState>({
    defaultValues: {
      reserveType,
      search: search ? search : {},
      regular_search: regularSearch ? regularSearch : {},
    },
  })
  const { register, setValue, watch } = useFormObject
  const getLocalStateValues = useFormObject.getValues

  // lintだとswiperの型が認識できないためチェックを無効にする
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [swiper, setSwiper] = React.useState<Swiper>(undefined)
  const swipeSpeed = isMobileDisplay()
    ? MOBIL_TRANSITION_SPEED
    : TRANSITION_SPEED
  const [conditionModalDisplay, setConditionModalDisplay] =
    React.useState<boolean>(true)
  const [prefectureModalDisplay, setPrefectureModalDisplay] =
    React.useState<boolean>(false)
  const [currentPrefecture, setPrefecture] =
    React.useState<IPrefecture>(undefined)

  const changeSearchTypeHandler = (selectedSerchType: ISearchType) => {
    initConditionStateWithSearchType(
      selectedSerchType,
      getLocalStateValues,
      setValue,
    )
  }

  const openPrefectureHandler = () => {
    setPrefectureModalDisplay(true)
    // lintだとswiperの型が認識できないためチェックを無効にする
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    swiper.slideNext(swipeSpeed)
  }

  const closePrefectureHandler = () => {
    setConditionModalDisplay(true)
    // lintだとswiperの型が認識できないためチェックを無効にする
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    swiper.slidePrev(swipeSpeed)
  }

  const resetPrefectureHandler = () => {
    setValue('search.geocode', {})
    setValue('search.station', {})
  }

  const openAreaSelectHandler = (
    prefectureId: number,
    prefectureNmae: string,
  ) => {
    setPrefecture({ id: prefectureId, name: prefectureNmae })
    // lintだとswiperの型が認識できないためチェックを無効にする
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    swiper.slideNext(swipeSpeed)
  }

  const closeAreaSelectHandler = () => {
    setPrefectureModalDisplay(true)
    // lintだとswiperの型が認識できないためチェックを無効にする
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    swiper.slidePrev(swipeSpeed)
  }

  const transitionEndHandler = (activeIndex: number) => {
    if (activeIndex === 0) {
      // 初期表示
      setPrefectureModalDisplay(false)
    } else if (activeIndex === 1) {
      // 都道府県表示
      setConditionModalDisplay(false)
      setPrefecture(undefined)
    } else if (activeIndex === 2) {
      // エリア表示
      setPrefectureModalDisplay(false)
      setConditionModalDisplay(false)
    }
  }

  const resetHandler = () => {
    trackEvent(eventIds.SEARCH_MODAL_CONDITION_RESET, user)

    setValue('reserveType', DEFAULT_STATE_RESERVE_TYPE)
    setValue('search', DEFAULT_STATE_SEARCH)
    setValue('regular_search', DEFAULT_STATE_REGULAR_SEARCH)
  }

  const submitHandler = () => {
    const items = makeSearchConditionTrackingData(watchAllFields, t)
    trackEvent({ ...eventIds.SEARCH_MODAL_CONDITION_SUBMIT, items }, user)

    props.submitHandler(watchAllFields)
  }

  const closeHandler = () => {
    trackEvent(eventIds.SEARCH_MODAL_CONDITION_CLOSE, user)

    props.closeHandler()
  }

  /*------------------ 表示イベントの計測 ------------------*/
  React.useEffect(() => {
    if (conditionModalDisplay) {
      trackEvent(eventIds.SEARCH_MODAL_CONDITION_VIEW, user)
    }
  }, [conditionModalDisplay])

  const watchAllFields = watch()
  const selectedGeocodes = watchAllFields.search.geocode
  const selectedStations = watchAllFields.search.station
  const selectedSearchType = watchAllFields.search.search_type
  const selectedReserveType = watchAllFields.reserveType

  // 本番の場合、件数取得に時間がかかりすぎるため一旦、機能をOFFにする
  /*
  React.useEffect(() => {
    if (showSearchCount && !!watchAllFields) {
      if (selectedReserveType === 'normal') {
        getSearchCount(watchAllFields)
          .then((result) => {
            if (result.result) {
              setTotalCount(result.res.total)
            }
          })
          .catch(() => void 0) // エラー時の処理を行う場合は実装する
      } else {
        getRegularSearchCount(watchAllFields)
          .then((result) => {
            if (result.result) {
              setTotalCount(result.res.total)
            }
          })
          .catch(() => void 0) // エラー時の処理を行う場合は実装する
      }
    }
  }, [JSON.stringify(watchAllFields)])
  */

  return (
    <SearchConditionPresenter
      submitLabel={submitLabel}
      conditionModalDisplay={conditionModalDisplay}
      prefectureModalDisplay={prefectureModalDisplay}
      currentPrefecture={currentPrefecture}
      prefectures={prefectures}
      selectedGeocodes={selectedGeocodes}
      selectedStations={selectedStations}
      selectedSearchType={selectedSearchType}
      selectedReserveType={selectedReserveType}
      setSwiper={setSwiper}
      register={register}
      getValues={getLocalStateValues}
      setValue={setValue}
      changeSearchTypeHandler={changeSearchTypeHandler}
      openPrefectureHandler={openPrefectureHandler}
      closePrefectureHandler={closePrefectureHandler}
      resetPrefectureHandler={resetPrefectureHandler}
      openAreaSelectHandler={openAreaSelectHandler}
      closeAreaSelectHandler={closeAreaSelectHandler}
      transitionEndHandler={transitionEndHandler}
      resetHandler={resetHandler}
      submitHandler={submitHandler}
      closeHandler={closeHandler}
    />
  )
}

export default SearchCondition
